<style></style>

<script>
import {Game} from "@/enums";
export default {
  props: ['game'],
  data() {
    return {
      Game: Game,
    }
  }
}
</script>
<!-- RCon configuration guide based on game title. -->
<template>
  <div>
    <template v-if="game === Game.DAYZ">
      <div class="row mb-4">
        <div class="col">
          <div class="alert alert-info">
            <h5 class="alert-heading">
              DayZ RCon configuration
            </h5>
            <hr>
            <p>
              RCon for DayZ requires additional configuration. You can get more information at <a
                href="https://docs.cftools.cloud/en/connection/battleye" target="_blank">docs.cftools.cloud/en/connection/battleye</a>.
            </p>
            <p>
              Make sure that <b class="text-code">RConPort</b> is configured and forwarded in your
              firewall. <b class="text-code">RestrictRCon</b> must be <b class="text-code">0</b> in
              order for CFTools Cloud to function correctly.
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
